/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import AudioPlayer from 'react-h5-audio-player';
import RichText from '~components/RichText';
import Container from '~components/Container';
import 'react-h5-audio-player/lib/styles.css';

const useStyles = makeStyles(() => ({
  media: {
    maxWidth: '100%',
    display: 'flex',
    margin: '0 auto'
  },
  audioPlayerStyles: {
    '& > div > .rhap_controls-section': {
      flex: 'unset'
    },
    '& > div > div > div > .rhap_main-controls-button': {
      color: '#458FCE'
    },
    '& > div > div > div > .rhap_play-pause-button': {
      fontSize: '55px',
      width: '55px',
      height: '55px'
    },
    '& > div > div > div > div .rhap_progress-indicator': {
      display: 'none'
    },
    '& > div > div > div > div .rhap_progress-filled': {
      backgroundColor: '#458FCE'
    },
    '& > .rhap_header': {
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '30px',
      marginLeft: '73px',
      marginBottom: '-15px'
    }
  },
  transcriptButtonStyle: {
    fontSize: '18px',
    lineHeight: '30px',
    marginTop: '10px',
    color: '#458FCE',
    textDecoration: 'underline',
    cursor: 'pointer'
  }
}));

const AudioFileSection = ({ section_title = {}, audio_files = [] }) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.only('xs'));

  const handleTranscriptClick = async () => {
    // explicitly do nothing
    // As of November 2024 printing transcripts to PDF on the current marketing website is deliberately broken.
  };

  return (
    <Container style={{ marginBottom: '100px', marginTop: '100px' }}>
      <RichText
        html={section_title && section_title.html}
        mode="teamPageSectionTitle"
        verticalSpacing={0}
      />
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
        spacing={isXs ? 2 : 7}
      >
        {audio_files &&
          audio_files.map(audio => (
            <Grid
              item
              xs={12}
              sm={4}
              key={audio && audio.audio_file_link && audio.audio_file_link.url}
            >
              <AudioPlayer
                src={audio && audio.audio_file_link && audio.audio_file_link.url}
                showJumpControls={false}
                layout="horizontal-reverse"
                customVolumeControls={[]}
                customAdditionalControls={[]}
                className={classes.audioPlayerStyles}
                header={audio && audio.title}
              />
              <RichText
                html={audio && audio.description && audio.description.html}
                mode="faqAudioPlayerText"
                verticalSpacing={0}
              />
              <Box
                onClick={() =>
                  handleTranscriptClick(
                    audio && audio.transcript && audio.transcript.html,
                    audio && audio.title
                  )
                }
                className={classes.transcriptButtonStyle}
              >
                Read the transcript
              </Box>
            </Grid>
          ))}
      </Grid>
    </Container>
  );
};

AudioFileSection.propTypes = {
  section_title: PropTypes.shape({
    html: PropTypes.string.isRequired
  }).isRequired,
  audio_files: PropTypes.arrayOf(
    PropTypes.shape({
      audio_file_link: PropTypes.shape({
        url: PropTypes.string.isRequired
      }).isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired,
      transcript: PropTypes.shape({
        html: PropTypes.string.isRequired
      }).isRequired
    })
  ).isRequired
};
export default AudioFileSection;
